import React from 'react';
import { Routes, Route, NavLink } from "react-router-dom";
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import Marquee from "react-fast-marquee";
import { ucfirst } from 'helpers';
import Index from 'pages/Index';
import UniffiMix from 'pages/UniffiMix';
import Mprimas from 'pages/Mprimas';
import logo from 'img/logo.png';

moment.locale(navigator.language || navigator.userLanguage);

const Container = styled.div`
	max-width: ${props => props.width ?? '500px'};
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;
`;

const Gradient = styled.div`
	background: rgb(194,14,125);
	background: linear-gradient(90deg, rgba(194,14,125,1) 0%, rgba(58,48,150,1) 80%);
`;

const Header = styled.header`
	text-align: center;
	padding: 10px;

	img {
		max-width: 180px;
	}
`;

const DateNav = styled.nav`
	display: flex;
	justify-content: center;
	font-size: 18px;
	padding: 5px;
	color: white;
`;

const Navbar = styled.nav`
	display: flex;
	justify-content: center;

	a {
		margin: 5px 20px;
		font-size: 20px;
		text-decoration: none;
		color: white;

		&.active {
			font-weight: bold;
			text-decoration: underline;
		}
	}
`;

const PageWrapper = styled.div`
	padding: 10px;
	height: 100%;
`;

function App() {

	let apiUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://grupoverbo.local' : 'https://grupoverbo.es/';

	const contactButtonClick = (type) => {
		if ( type === 'email' ) window.location.href = 'mailto:ramonverbo@gmail.com';
		if ( type === 'phone' ) window.location.href = 'tel:+34629109157';
		if ( type === 'whatsapp' ) window.open('https://wa.me/34629109157');
	}

  	return (
  		<React.Fragment>
  			<Gradient>
				<Container>
					<Header>
						<div className="row">
							<div className="col-4">
								<NavLink to="/"><img src={logo} alt="logo" /></NavLink>
							</div>
							<div className="col-8">
								<div className="d-flex justify-content-end align-items-center h-100">
									<button className="btn btn-outline-light me-3" onClick={() => contactButtonClick('email')}><i className="bi bi-envelope"></i></button>
									<button className="btn btn-outline-light me-3" onClick={() => contactButtonClick('phone')}><i className="bi bi-telephone"></i></button>
									<button className="btn btn-outline-light" onClick={() => contactButtonClick('whatsapp')}><i className="bi bi-whatsapp"></i></button>
								</div>
							</div>
						</div>
					</Header>
					<DateNav>
						<Marquee gradient={false} speed={50}>
							Precios para el { moment().format('D') } de { ucfirst(moment().format('MMMM')) } de { moment().format('YYYY') }
						</Marquee>
					</DateNav>
					<Navbar>
						<NavLink to="/uniffi">Uniffi <i className="bi bi-chevron-down"></i></NavLink>
						<NavLink to="/materias-primas">M. Primas <i className="bi bi-chevron-down"></i></NavLink>
					</Navbar>
				</Container>
			</Gradient>
			<Container width="100%">
				<PageWrapper>
					<Routes>
				        <Route path="/" element={<Index />} />
				        <Route path="uniffi" element={<UniffiMix apiUrl={apiUrl} />} />
				        <Route path="materias-primas" element={<Mprimas apiUrl={apiUrl} />} />
			      	</Routes>
				</PageWrapper>
			</Container>
		</React.Fragment>
  	);
}

export default App;
