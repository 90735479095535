import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Table = styled.table`
	thead {
		tr {
			th {
				&:nth-child(1) {
					width: 80px;
				}

				&:nth-child(3) {
					text-align: right;
					width: 80px;
				}
			}
		}
	}

	tbody {
		tr {
			td {
				&:nth-child(3) {
					text-align: right;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Mprimas(props) {
	const [loading, setLoading] = useState(false);
	const [uniffis, setUniffis] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getUniffis = async () => {
			setLoading(true);

			await axios.get(props.apiUrl + '/api/uniffi/all-public', {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setUniffis([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

			setLoading(false);
		}

		getUniffis();
	}, [props.apiUrl]);

	return (
		<div>
			<Table className="table table-sm table-bordered">
				<thead>
					<tr>
						<th>Código</th>
						<th>Descripción</th>
						<th>Precio</th>
					</tr>
				</thead>
				<tbody>
					{ loading &&
						<tr>
							<td colSpan="100%">Cargando...</td>
						</tr>
					}

					{ (!loading && uniffis.length > 0) &&
						uniffis.map((el, idx) => {
							return (
								<tr key={idx}>
									<td>{el.code}</td>
									<td>{el.description}</td>
									<td>{el.price} €</td>
								</tr>
							);
						})
					}
				</tbody>
			</Table>
		</div>
	);
}