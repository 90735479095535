import styled from 'styled-components';
import initialBackground from 'img/initial-background.jpg';

const IndexStyled = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
	}
`;

export default function Index() {
	return (
		<IndexStyled>
			<img src={initialBackground} alt="initial-background" />
		</IndexStyled>
	);
}