import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';

const Table = styled.table`
	thead {
		tr {
			th {
				&:nth-child(1) {
					width: 80px;
				}

				&:nth-child(3) {
					text-align: right;
					width: 80px;
				}
			}
		}
	}

	tbody {
		tr {
			&:hover {
				cursor: pointer;
			}

			td {
				&:nth-child(3) {
					text-align: right;
				}
			}
		}
	}
`;

const ModalStyled = styled.div`
	.modal-header {
		.modal-title {
			font-size: 16px;
		}
	}

	table#basic-info {
		width: 100%;
		font-size: 13px;
		line-height: 16px;

		tr {
			td {
				vertical-align: top !important;

				&:first-of-type {
					font-weight: 500;
				}

				&:last-of-type {
					padding-left: 10px;
				}
			}
		}
	}

	table#detailed-info {
		font-size: 13px;

		th {
			font-weight: 600;
		}

		th:not(:first-of-type),
		td:not(:first-of-type) {
			text-align: right
		}
	}
`;

let axiosCancelToken = null;

export default function UniffiMix(props) {
	const [loading, setLoading] = useState(false);
	const [uniffimixes, setUniffimixes] = useState([]);
	const [modalIdx, setModalIdx] = useState(null);

	const modalRef = useRef(null);
	const modalObjRef = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getUniffimixes = async () => {
			setLoading(true);

			await axios.get(props.apiUrl + '/api/uniffimixes/all-public', {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setUniffimixes([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

			setLoading(false);
		}

		getUniffimixes();
	}, [props.apiUrl]);

	const openModal = (idx) => {
		setModalIdx(idx);

		modalObjRef.current = new Modal(modalRef.current, {
			// backdrop: false
		});

		const hiddenEvent = (e) => {
           	modalObjRef.current._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modalObjRef.current._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modalObjRef.current.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modalObjRef.current._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modalObjRef.current.show();
	}

	// const hideModal = () => {
	// 	modalObjRef.current.hide();
	// }

	return (
		<>
			<Table className="table table-sm table-bordered table-hover">
				<thead>
					<tr>
						<th>Código</th>
						<th>Descripción</th>
						<th>Precio</th>
					</tr>
				</thead>
				<tbody>
					{ loading &&
						<tr>
							<td colSpan="100%">Cargando...</td>
						</tr>
					}

					{ (!loading && uniffimixes.length > 0) &&
						uniffimixes.map((el, idx) => {
							return (
								<tr key={idx} onClick={() => openModal(idx)}>
									<td>{el.code}</td>
									<td>{el.description}</td>
									<td>{el.price} €</td>
								</tr>
							);
						})
					}
				</tbody>
			</Table>
			<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						{ modalIdx !== null &&
							<>
								<div className="modal-header">
									<h5 className="modal-title">{ uniffimixes[modalIdx].code } { uniffimixes[modalIdx].description }</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className="modal-body">
									<table id="basic-info">
										<tbody>
											<tr>
												<td>Uniffi</td>
												<td>: { uniffimixes[modalIdx].code } { uniffimixes[modalIdx].description }</td>
											</tr>
											<tr>
												<td>Precio</td>
												<td>: {uniffimixes[modalIdx].price} €</td>
											</tr>
											<tr>
												<td>% Proteína Bruta</td>
												<td>: {uniffimixes[modalIdx].protein_percent} %</td>
											</tr>
											<tr>
												<td>% Fibra Bruta</td>
												<td>: {uniffimixes[modalIdx].fiber_percent} %</td>
											</tr>
											<tr>
												<td>% Act. y Gra. Bruta</td>
												<td>: {uniffimixes[modalIdx].fat_percent} %</td>
											</tr>
											<tr>
												<td>% Ceniza Bruta</td>
												<td>: {uniffimixes[modalIdx].ashes_percent} %</td>
											</tr>
										</tbody>
									</table>
 
									<table className="table table-sm table-bordered mt-3 mb-0" id="detailed-info">
										<thead>
											<tr>
												<th>Materia Prima</th>
												<th>Cien %</th>
												<th>Kilos</th>
											</tr>
										</thead>
										<tbody>
											{uniffimixes[modalIdx].parts?.sort((a, b) => {
												if ( a.percent < b.percent ) return 1;
												if ( a.percent > b.percent ) return -1;
												return 0;
											}).map((el, idx) => {
												return (
													<tr key={idx}>
														<td>{el.code} {el.name}</td>
														<td>{el.percent} %</td>
														<td>{el.kg} Kg</td>
													</tr>
												);
											})}
										</tbody>
										<tfoot>
											<tr>
												<td></td>
												<td>{uniffimixes[modalIdx].parts?.reduce((acc, obj) => acc += obj.percent, 0)} %</td>
												<td>{uniffimixes[modalIdx].parts?.reduce((acc, obj) => acc += obj.kg, 0)} Kg</td>
											</tr>
										</tfoot>
									</table>
								</div>
								{/*<div className="modal-footer">
									<button type="button" className="btn btn-light" onClick={hideModal}>Cerrar</button>
								</div>*/}
							</>
						}
					</div>
				</div>
			</ModalStyled>
		</>
	);
}